<template>
    <div class="content-wrapper">
        <PageHeader screenName="Cadastrar novo" :linkItems="linkItems"/>

        <ConfirmModal 
            itemIdentifier="usuário"
            :modalShow="modal.showModal"
            :typeModal="modal.typeModal"
            @confirmed="redirectToList"
            @canceled="closeModalConfirm"
        />

        <SucessoModal
            :modalShow="modalSucesso.showModal"
            :typeModal="modalSucesso.typeModal"
            :caseModal="modalSucesso.caseModal"
            @confirmed="redirectToList"
        />

        <TimedAlert variant="success" message="Aviso salvo com sucesso!" :show="savedAlert" />

        <main class="card p-2">
            <section class="form">
                <validation-observer ref="avisoRules">
                    <b-form>
                        <b-row>
                            <b-col lg="8" md="8" sm="12">
                                <b-form-group label="Título do aviso*">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="titulo"
                                        rules="required"
                                    >
                                    <b-form-input 
                                            :state="errors.length > 0 || form.errorApi.titulo || form.errorApi.error ? false:null"
                                            id="titulo-input" 
                                            v-model="form.titulo"
                                            placeholder="Adicione um título para este aviso"
                                    />
                                        <small v-if="errors[0]" class="text-danger">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errorApi.titulo" class="text-danger">
                                            {{ form.errorApi.titulo }}
                                        </small>
                                        <small v-if="form.errorApi.error" class="text-danger">
                                            {{ form.errorApi.error }}
                                        </small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="12" md="12" sm="12">
                                <b-form-group label="Descrição do aviso*">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="conteudo"
                                        rules="required"
                                    >
                                        <b-form-textarea  
                                            :state="errors.length > 0 || form.errorApi.conteudo || form.errorApi.error ? false:null"
                                            id="conteudo-input"
                                            rows="5"
                                            v-on:keyup="liveCountDown"
                                            v-model="form.conteudo"
                                            placeholder="Adicione uma descrição para este aviso"
                                            no-resize
                                        />
                                        <small v-if="errors[0]" class="text-danger">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errorApi.conteudo" class="text-danger">
                                            {{ form.errorApi.conteudo }}
                                        </small>
                                        <small v-if="form.errorApi.error" class="text-danger">
                                            {{ form.errorApi.error }}
                                        </small>
                                        <small v-if="errorTextarea" class="text-danger">
                                            {{ errorTextarea }}
                                        </small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                           <b-col lg="6" md="6" sm="12" v-if="agendamento">
                               <b-form-group label="Início">
                                <validation-provider
                                    name="vigencia_inicial"
                                >
                                <b-input-group>
                                        <vue-cleave
                                            id="vigencia_final-input"
                                            v-model="input.vigencia_inicial"
                                            :raw="false"
                                            :options="data"
                                            class="form-control"
                                            placeholder="00/00/0000"
                                            name="vigencia_inicial"
                                            autocomplete="off"
                                            @input="setVigencia_inicialInput"
                                        />
                                        <b-input-group-append>
                                            <b-form-datepicker
                                                locale="pt-BR"
                                                button-only
                                                right
                                                :hide-header="true"
                                                v-model="form.vigencia_inicial"
                                                @input="setVigencia_inicialPicker"
                                                class="form-control d-flex align-items-center"
                                                label-help="Selecione a data de início"
                                                :min="today"
                                                button-variant="custom-blue"
                                                cleareabled
                                            />
                                            </b-input-group-append>
                                    </b-input-group>
                                        <small v-if="form.errorApi.vigencia_inicial" class="text-danger">
                                            {{ form.errorApi.vigencia_inicial }}
                                        </small>
                                        <small v-if="form.errorApi.error" class="text-danger">
                                            {{ form.errorApi.error }}
                                        </small>
                                </validation-provider>
                               </b-form-group>
                               <b-form-group label="Término">
                                   <validation-provider
                                    name="vigencia_final"
                                    >
                                    <b-input-group>
                                        <vue-cleave
                                            id="vigencia_final-input"
                                            v-model="input.vigencia_final"
                                            :raw="false"
                                            :options="data"
                                            class="form-control"
                                            placeholder="00/00/0000"
                                            name="vigencia_final"
                                            autocomplete="off"
                                            @input="setVigencia_finalInput"
                                        />
                                        <b-input-group-append>
                                        <b-form-datepicker
                                        locale="pt-BR"
                                        button-only
                                        right
                                        :hide-header="true"
                                        v-model="form.vigencia_final"
                                        :min="form.vigencia_inicial ? form.vigencia_inicial : today"
                                        class="form-control d-flex align-items-center"
                                        label-help="Selecione a data de término"
                                        placeholder="Selecione a data de término"
                                        button-variant="custom-blue"
                                        clearable
                                        @input="setVigencia_finalPicker"
                                        />
                                        </b-input-group-append>
                                    </b-input-group>
                                        <small v-if="form.errorApi.vigencia_final" class="text-danger">
                                            {{ form.errorApi.vigencia_final }}
                                        </small>
                                        <small v-if="form.errorApi.error" class="text-danger">
                                            {{ form.errorApi.error }}
                                        </small>
                                   </validation-provider>
                               </b-form-group>
                            </b-col>
                            <b-col lg="6" md="6" sm="12">
                                <b-form-group label="Perfis a serem encaminhados*">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Perfis"
                                        rules="required"
                                    >
                                        <input type="hidden" v-model="perfisSelecionados">
                                        <SelectPerfis
                                        @GetPerfis="setPerfil"
                                        :values="perfisSelecionados"
                                        :state="errors.length > 0 || form.errorApi.perfisSelecionados ? false:null"
                                        />
                                        <small class="text-muted">
                                            Mais de uma opção pode ser selecionada.
                                        </small>
                                        <br>
                                        <small v-if="errors[0]" class="text-danger">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errorApi.perfisSelecionados" class="text-danger">
                                            {{ form.errorApi.perfisSelecionados }}
                                        </small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col lg="6" md="6" sm="12">
                                    <validation-provider
                                    #default="{ errors }"
                                    name="ajudaRelacionada"
                                >
                                    <b-form-group 
                                    label="Ajuda relacionada"
                                    :state="errors.length > 0 || form.errorApi.ajudaRelacionada ? false:null">
                                        <v-select 
                                            id="ajudaRelacionada"
                                            v-model="form.ajudaRelacionada"
                                            :options="ajudaRelacionadaSelect"
                                            label="topico"
                                            placeholder="Selecione a ajuda relacionada a esse aviso"
                                            :clearable="true"
                                        >
                                            <span slot="no-options">Nenhuma opção selecionável.</span>
                                        </v-select>
                                        <small v-if="errors[0]" class="text-danger">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errorApi.ajudaRelacionada" class="text-danger">
                                            {{ form.errorApi.ajudaRelacionada }}
                                        </small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                        </b-row>
                    </b-form>
                </validation-observer>
                 
            </section>
            <section class="buttons mt-3">
                <b-button id="save-aviso" 
                    class="mr-2 mb-2-sm" 
                    variant="custom-blue"
                    @click.prevent="validationForm(true)"
                    :disabled="saving || savingAndNew"
                >
                    <feather-icon icon="CheckIcon" class="mr-50"/>
                    <span class="align-middle">Salvar aviso</span>
                    <span v-if="saving" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
                </b-button>
                <b-button id="save-new-aviso" 
                    class="mr-2 mb-2-sm" 
                    variant="custom-blue"
                    @click.prevent="validationForm(false)"
                    :disabled="saving || savingAndNew"
                >
                    <feather-icon icon="CheckIcon" class="mr-50"/>
                    <span class="align-middle">Salvar e cadastrar uma nova</span>
                    <span v-if="savingAndNew" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
                </b-button>
                <b-button id="cancel-register-aviso" 
                    :disabled="saving || savingAndNew"
                    variant="outline-danger"
                    @click.prevent="openModalConfirm"
                >
                    <feather-icon icon="XIcon" class="mr-50"/>
                    <span class="align-middle">Cancelar</span>
                </b-button>
            </section>
        </main>
    </div>
</template>

<script>
import { BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadio, BButton,
 BFormTextarea, BFormDatepicker, BCardText, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import moment from 'moment'
import VueCleave from 'vue-cleave-component'
import TimedAlert from '@/views/components/custom/timed-alert/TimedAlert.vue'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import ConfirmModal from '@/views/components/custom/modals/ConfirmModal.vue'
import SelectPerfis from '@/views/custom-pages/aviso/Select-perfis.vue'
import SucessoModal from '@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue'
import { TIPONOTIFICACAO } from '@/enum/tipo-notificacao'

export default {
    title: 'Cadastrar aviso',
    components: {
        BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadio, BFormTextarea,
        BButton, vSelect, ValidationProvider, ValidationObserver,
        TimedAlert, PageHeader, ConfirmModal, SelectPerfis, SucessoModal,
        BFormDatepicker, BCardText, VueCleave, BInputGroup, BInputGroupAppend
    },
    data() {
        return {
            linkItems: [
                {
                    name: 'Avisos',
                    routeName: 'aviso-list'
                },
                {
                    name: 'Cadastrar novo',
                    active: true
                }
            ],
            modal: {
                showModal: false,
                typeModal: 'danger'
            },
            modalSucesso: {
                showModal: false,
                typeModal: 'success',
                caseModal: 'criacao'
            },
            data: {
                date: true,
                delimiter: '/',
                datePattern: ['d', 'm', 'Y'],
            },
            required,
            savedAlert: false,
            saving: false,
            savingAndNew: false,
            limitmaxCount: 250,
            totalRemainCount: 250,
            errorTextarea: '',
            minDate: moment().format('YYYY-MM-DD'),
            today: moment().format('YYYY-MM-DD'),
            agendamento: false,
            form: {
                titulo:  '',
                conteudo: '',
                vigencia_inicial: '',
                vigencia_final: '',
                ajudaRelacionada: null,
                errorApi: {
                    titulo:  '',
                    conteudo: '',
                    vigencia_inicial: '',
                    vigencia_final: '',
                    ajudaRelacionada: '',
                    perfisSelecionados: '',
                    error: ''
                }
            },
            input: {
                vigencia_inicial: '',
                vigencia_final: '',
            },
            perfisSelecionados: [],
            ajudaRelacionadaSelect: []

        }
    },
    mounted(){
        this.$http.get(this.$api.ajuda()).then(({ data }) => {
            this.ajudaRelacionadaSelect = data.data;
        });
    },
    methods: {
        validationForm(redirectToList) {
            this.$refs.avisoRules.validate().then(success => {
                if (success) {
                    if(redirectToList){
                        this.saving = true
                    }else{
                        this.savingAndNew = true;
                    }
                    this.saveAviso(redirectToList);
                }
            })
        },
        saveAviso(redirectToList) {
            const parameters = {
                'titulo': this.form.titulo,
                'conteudo': this.form.conteudo,
                'id_tipo_notificacao': TIPONOTIFICACAO.AVISO,
                'vigencia_inicial': this.form.vigencia_inicial ? this.form.vigencia_inicial : moment().format('YYYY-MM-DD') ,
                'vigencia_final': this.form.vigencia_final ? this.form.vigencia_final : this.validateVigencia_final(this.form.vigencia_final, this.form.vigencia_inicial),
                'id_ajuda': this.form.ajudaRelacionada ? this.form.ajudaRelacionada.id_ajuda : '',
                'ids_perfis': this.$helpers.retornaAtributoArray(this.perfisSelecionados, 'id_perfil')
            }

            this.$http.post(this.$api.saveAndGetNotificacao(), parameters)
            .then(() => {
                if(redirectToList) {
                    this.openModalSucesso();
                }else{
                    this.savedAlert = !this.savedAlert;
                }

                this.saving = false;
                this.savingAndNew = false;
                this.cleanForm();

            }).catch(({ response: { data } }) => {
                handleErrorsService.handleErrosAviso(data, this.form.errorApi);

                this.saving = false;
                this.savingAndNew = false;
            })
        },
        cleanForm() {
            this.form.titulo = '';
            this.form.conteudo = '';
            this.form.vigencia_inicial = '';
            this.form.vigencia_final = '';
            this.form.ajudaRelacionada = '';
            this.perfisSelecionados = [];

            this.form.errorApi.titulo = '';
            this.form.errorApi.conteudo = '';
            this.form.errorApi.vigencia_inicial = '';
            this.form.errorApi.vigencia_final = '';
            this.form.errorApi.ajudaRelacionada = '';
            this.form.errorApi.error = '';

            this.$refs.avisoRules.reset();
        },

        clearDataVigencia_final(){
            this.form.vigencia_final = null;
            this.input.vigencia_final = null;
        },

        redirectToList() {
            this.$router.push({ name: 'aviso-list' });
        },
        openModalConfirm() {
            this.modal.showModal = true;
        },

        closeModalConfirm() {
            this.modal.showModal = false;
        },
        openModalSucesso() {
            this.modalSucesso.showModal = true;
        },
        setPerfil(perfilId) { 
            this.perfisSelecionados = perfilId;
        },
        setVigencia_inicialPicker() {
            this.clearDataVigencia_final();
            this.input.vigencia_inicial = moment(this.form.vigencia_inicial).format('DD/MM/YYYY');
        },
        setVigencia_inicialInput() {
            this.clearDataVigencia_final();
            this.form.vigencia_inicial = moment(this.input.vigencia_inicial, 'DD/MM/YYYY').format('YYYY-MM-DD');
        },
        setVigencia_finalPicker() {
            this.input.vigencia_final = moment(this.form.vigencia_final).format('DD/MM/YYYY');
        },
        setVigencia_finalInput() {
            this.form.vigencia_final = moment(this.input.vigencia_final, 'DD/MM/YYYY').format('YYYY-MM-DD');
        },
        validateVigencia_final(vigencia_final, vigencia_inicial){
            if(vigencia_inicial && !vigencia_final) {
                return moment(vigencia_inicial).add(7, 'd').format('YYYY-MM-DD');
            }else{
                return moment().add(7, 'd').format('YYYY-MM-DD');
            }
        },
        liveCountDown() {
            this.totalRemainCount = this.limitmaxCount - this.form.conteudo.length;
            if(this.totalRemainCount < 0){
                this.errorTextarea = 'O número de caracteres foi excedido.'
            }else{
                this.errorTextarea = '';
            }
        }
    }
}
</script>